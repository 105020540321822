import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="infopriv">Para quaisquer esclarecimentos:</div>

      <div style={{marginTop: 4}}>
        <a
          href="https://tecnorise.com/politica-de-privacidade/index.html"
          target="_blank"
        >
          Política de privacidade |{" "}
        </a>
        <a href="https://tecnorise.com/termo-de-uso/index.html" target="_blank">
          Termo de uso
        </a>
      </div>
    </footer>
  );
}

export default Footer;
