
import React, { useEffect, useState, useRef, useCallback } from 'react'
import Input from '../components/form/Input'
import Footer from '../components/Footer'
import Dica from '../assets/rostoRegra.png'
import Switch from "react-switch";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Checked from '../assets/checked.png'
import axios from 'axios';
import WebcamComponent from './WebcamComponent';
import Face from './Face';
import Preload from './PreLoad'
import Success from '../components/alerta/success'
import Erro from '../components/alerta/erro'
import TelaInformacao from './informacao'
// import TelaNovoCadastro from './novoCadastroForm'
import './QrCode.css'




const PreCadastro = props => {

    const [toggle, setToggle] = useState();

    const [morador, setMorador] = useState([]);
    const [form, setForm] = useState({});
    const [tel, setTel] = useState("");

    const [toggleServices, setToggleServices] = useState(false);
    const [toggleCar, setToggleCar] = useState(false);
    const [termo, setTermo] = useState(false);

    const [image, setImage] = useState();

    const [erroTextCPF, setErroTextCPF] = useState("");
    const [formErros, setFormErros] = useState({});
    const [loading, setLoading] = useState(false);
    const [userJaCadastrado, setUserJaCadastrado] = useState(false);
    const [dadosConvite, setDadosConvite] = useState();
    const [msgLinkExpirado, setMsgLinkExpirado] = useState("");
    const [latLong, setLatLong] = useState({});

    useEffect(() => {
        if (toggle === "cadastro") setForm({})
    }, [toggle]);

    useEffect(() => {
        const IDFastpass = window.location.href.split("?")[1];
        let tentativas = 0;

        async function carregarConvite(IDFastpass) {

            setToggle('preload')
            tentativas = tentativas + 1;
            const url = "https://fastpass-br.gear-tecnorise.com:4001/public/invitation/list/" + IDFastpass

            try {

                const res = await axios.get(url);
                if (res && res.data && res.data.status) {
                    setDadosConvite(res.data.resultado);
                    setToggle('condinfo');
                } else {
                    setErroTextCPF(res.data.mensagem)
                    setToggle('erro');
                };
            } catch (e) {
                if (tentativas <= 2) {
                    carregarConvite(IDFastpass)
                } else {
                    setToggle('erroCarregamentoDaURL');
                };

            } finally {
                // setLoading(false);
            };
        };

         carregarConvite(IDFastpass);

        // setToggle('newcadaster');

    }, []);



    //webcam
    const finalizarFoto = async ({ proxTela, fotoBase64 }) => {
        if (dadosConvite.id_visitor) {
            setToggle("fotoNaoReconhecida");
        } else {
            setToggle(proxTela);
            if (fotoBase64) setImage(fotoBase64);
        };
    };

    const fecharCamera = () => {
        setToggle('condinfo');
    };

    const changeForm = (campo, valor) => {
        setFormErros(prevForm => ({ ...prevForm, [campo]: undefined }));
        setForm(prevForm => ({ ...prevForm, [campo]: valor }));
    };

    const saveData = async e => {

        e.preventDefault();
        let erros = {};

        if (!termo) erros.termo = true;
        if (!form.nome || form.nome.length <= 3) erros.nome = true;
        if (!tel || tel.length < 8) erros.tel = true;

        if (toggleServices) {
            if (!form.empresa || form.empresa.length <= 2) erros.empresa = true;
            if (!form.funcao || form.funcao.length <= 2) erros.funcao = true;
        };

        if (toggleCar) {
            if (!form.marca || form.marca.length <= 2) erros.marca = true;
            if (!form.modelo || form.modelo.length <= 2) erros.modelo = true;
            if (!form.placa || form.placa.length <= 2) erros.placa = true;
        };


        if (Object.keys(erros).length > 0) {
            setFormErros(erros)
            return
        };

        const newMorador =
        {
            "id_unit": dadosConvite.id_unity,
            "name": form.nome,
            "base64_photo": image.replace("data:image/jpeg;base64,", ""),
            "id_invitation_list": dadosConvite.id_invitation_list,
        };

        if (tel && tel.slice(0, 2) == "55") {
            newMorador.cell_number = tel.slice(2);
        } else {
            newMorador.cell_number = tel;
        };

        if (toggleServices) {
            newMorador.company = form.empresa
            newMorador.occupation = form.funcao
        };

        if (toggleCar) {
            newMorador.vehicle = {
                "vehicle_type": form.tipoVeiculo ? String(form.tipoVeiculo) : "0",
                "vehicle_brand": form.marca,
                "model": form.modelo,
                "license_plate": form.placa,
                "color": ""
            };
        };



        try {
            setToggle('preload');

            if (userJaCadastrado) {
                newMorador.id_visitor = userJaCadastrado;
                const res = await axios.put('https://fastpass-br.gear-tecnorise.com:4001/public/visitor/invitation/list', newMorador);
                if (res.data.status) {
                    setToggle('realizado');
                } else {
                    setErroTextCPF(res.data.mensagem)
                    setToggle('erro');
                }
            } else {
                newMorador.cpf = form.cpf;
                const res = await axios.post('https://fastpass-br.gear-tecnorise.com:4001/public/visitor/invitation/list', newMorador);
                if (res.data.status) {
                    setToggle('realizado');
                } else {
                    setErroTextCPF(res.data.mensagem)
                    setToggle('erro');
                }
            };

        } catch (e) {
            setErroTextCPF('')
            setToggle('erro');
        } finally {
            // setLoading(false);
        }

        setMorador(prevMorador => [...prevMorador, newMorador])

    };

    const changeSwitch = () => {
        if (!termo === true) {
            changeForm('termos', 'aceitos')
        }
        setTermo(prevState => !prevState)
    };

    async function validarDocumento () {
        const consultarCPF = async (cpf, id_unit) => {
            try {
                //setLoading(true);
                const res = await axios.post('https://fastpass-br.gear-tecnorise.com:4001/public/visitor/cpf', {
                    cpf: cpf,
                    id_unit
                })

                if (res.data && res.data.status) {
                    setForm(prev => ({ ...prev, nome: res.data.resultado.name, }))
                    setTel(res.data.resultado.cell_number.length === 11 ? '55' + res.data.resultado.cell_number : res.data.resultado.cell_number)
                    setUserJaCadastrado(res.data.resultado.id);
                    return true;
                } else {
                    //setUserJaCadastrado(false);
                    // setForm({})

                    return false;
                };

            } catch (e) {
                //setUserJaCadastrado(false);
                return false;
            } finally {
                //setLoading(false);
            };
        };

        if (form.cpf) {

            const resConsultaCPF = await consultarCPF(form.cpf, dadosConvite.id_unity);
            if (resConsultaCPF) {
                setToggle('newcadaster');
            } else {
                setToggle('newcadaster');
            };
        } else {
            setErroTextCPF('Informe um Documento válido.');
        };

    };

    // const functionsCPF = {
    //     validar: async () => {

    //         function TestaCPF(cpf) {
    //             if (!cpf) return false
    //             let Soma;
    //             let Resto;
    //             Soma = 0;

    //             let strCPF = cpf.replace('.', "").replace('.', "").replace('-', "");
    //             if (strCPF == "00000000000") return false;

    //             for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    //             Resto = (Soma * 10) % 11;

    //             if ((Resto == 10) || (Resto == 11)) Resto = 0;
    //             if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    //             Soma = 0;
    //             for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    //             Resto = (Soma * 10) % 11;

    //             if ((Resto == 10) || (Resto == 11)) Resto = 0;
    //             if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    //             return true;
    //         };

    //         const consultarCPF = async (cpf, id_unit) => {
    //             try {
    //                 // setLoading(true);
    //                 // setToggle('preload')
    //                 const res = await axios.post('https://fastpass-br.gear-tecnorise.com:4001/public/visitor/cpf', {
    //                     cpf: cpf,
    //                     id_unit
    //                 })

    //                 if (res.data && res.data.status) {
    //                     setForm(prev => ({ ...prev, nome: res.data.resultado.name, }))
    //                     setTel(res.data.resultado.cell_number.length === 11 ? '55' + res.data.resultado.cell_number : res.data.resultado.cell_number)
    //                     setUserJaCadastrado(res.data.resultado.id);
    //                     return true;
    //                 } else {
    //                     //setUserJaCadastrado(false);
    //                     return false;

    //                 };

    //             } catch (e) {
    //                 //setUserJaCadastrado(false);
    //                 return false;
    //             } finally {
    //                 // setLoading(false);
    //             };
    //         };

    //         const resTesteCPF = TestaCPF(form.cpf);
    //         if (resTesteCPF) {

    //             setToggle('preload')

    //             const resConsultaCPF = await consultarCPF(form.cpf, dadosConvite.id_unity);

    //             if (resConsultaCPF) {
    //                 setToggle('newcadaster');
    //             } else {
    //                 setToggle('newcadaster');
    //             };
    //         } else {
    //             setErroTextCPF('Informe um CPF válido.');
    //         };
    //     },
    //     mask: cpf => {
    //         return cpf
    //             .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    //             .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    //             .replace(/(\d{3})(\d)/, '$1.$2')
    //             .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    //             .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    //     },
    // };

    const fotoEncontrada = ({ nome, id, fotoBase64, cpf }) => {
        if (dadosConvite.id_visitor) {
            setForm(prev => ({ ...prev, nome, cpf }));
            setUserJaCadastrado(id);
            setToggle('qrcode');
            if (fotoBase64) setImage(fotoBase64);
        } else {
            setForm(prev => ({ ...prev, nome, cpf }));
            setUserJaCadastrado(id);
            setToggle('verificarCPF');
            if (fotoBase64) setImage(fotoBase64);
        };
    };

    const titleCase = str => `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`


    if (toggle === 'realizado') {
        return (
            <Success>
                <div >
                    <span>Cadastro Realizado com sucesso!</span><br/><br/>
                    <span>Após a liberação por parte de <b>{titleCase(dadosConvite.name_created || "")}</b> você receberá um novo link com detalhes do convite.</span>
                </div>
            </Success>
        )
    };


    return (
        <div>

 
            {toggle === 'fotoNaoReconhecida' && (
                <div className="cond">

                    <header className="headerCond">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: "#dc3545",
                                color: "white",
                                borderRadius: "60px 60px 60px 60px",
                                fontSize: "50px",
                                width: "90px",
                                height: "90px",
                                marginTop: "90px",
                            }}
                        >
                            X
                        </div>
                        <p
                            style={{
                                marginTop: 34,
                                fontSize: 18
                            }}
                        >
                            Foto não reconhecida
                        </p>
                        <p
                            style={{
                                margin: 46,
                                marginTop: 14,
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            Entre em contato com o anfitrião para receber um novo convite.
                        </p>
                    </header>

                    <Footer />
                </div>
            )}

          

            {toggle === 'condinfo' && (

                <TelaInformacao info={dadosConvite} onClick={() => setToggle('cadastro')}/>
          
            )}

            {toggle === 'cadastro' && (
                <WebcamComponent
                    finalizarFoto={finalizarFoto}
                    fecharCamera={fecharCamera}
                    fotoEncontrada={fotoEncontrada}
                    dadosConvite={dadosConvite}
                />
            )}


            {/* {toggle === 'erro' && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>Opss... Você precisa fazer o reconhecimento facial</div>
            )} */}

            {toggle === 'erroCarregamentoDaURL' && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        textAlign: 'center',
                        marginBottom: 100
                    }}>
                    Erro ao carregar informações. Por favor, recarregue a página.
                </div>
            )}

            {toggle === 'verificarCPF' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'

                    }}
                >
                    <div
                        className="cardFoto"
                        style={{
                            display: "flex",
                            marginTop: 20,
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: "center center",
                            marginTop: 50,
                            marginBottom: 40,
                        }}
                    />


                    <div className="input-data">
                        <label>{'Digite seu Documento'}</label>
                        <input
                            value={form.cpf || ""}
                            disabled={toggle === 'verificarCPF' ? false : true}
                            onChange={e => {
                                setErroTextCPF('');
                                changeForm('cpf', e.target.value)
                            }}
                        />
                        {erroTextCPF.length === 0 ?
                            <div
                                style={{
                                    marginTop: 4,
                                    fontSize: 12,
                                    color: 'lightgray',
                                }}

                            >
                                Campo obrigatório
                            </div>
                            :
                            <div
                                style={{
                                    color: 'red',
                                    fontSize: 12,
                                    marginTop: 6,
                                }}
                            >
                                {erroTextCPF}
                            </div>
                        }

                    </div>


                    <div style={{
                        display: 'flex',
                        width: 360,
                        justifyContent: 'space-between'
                    }}>

                        <button
                            style={{ color: 'rgb(164, 164, 164)', border: '1px solid rgb(164, 164, 164)' }}
                            className='button'
                            onClick={() => setToggle('cadastro')}
                        >
                            Tirar outra foto
                        </button>

                        <button
                            className='button'
                            onClick={validarDocumento}
                        >
                            Avançar
                        </button>

                    </div>

                    <Footer />
                </div>
            )}


           {toggle === 'newcadaster' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <div
                        className="cardFoto"
                        style={{
                            display: "flex",
                            marginTop: 20,
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: "center center"
                        }}
                    />

                    <div
                        style={{
                            marginTop: 20
                        }}
                    >

                    </div>

                    <Input
                        type='text'
                        text='Nome e Sobrenome'
                        value={form.nome}
                        onChange={e => changeForm('nome', e.target.value)}
                        style={{ marginTop: 10 }}
                        required={true}
                        erro={formErros.nome}
                        erroText="Digite seu nome e sobrenome"
                    />
                    <Input
                        type='text'
                        text='Documento'
                        value={form.cpf}
                        onChange={e => changeForm('cpf', e.target.value)}
                        disabled={true}
                    />

                    <div
                        className="input-data"
                        style={{
                            margin: 0
                        }}>
                        <label>{'Contato'}</label>
                    </div>

                    <PhoneInput
                        className='phoneInput'
                        country='br'
                        value={tel}
                        onChange={phone => setTel(phone)}
                        inputStyle={{
                            width: '100%',
                            border: 'none',
                            borderBottom: '1px solid #9f9f9f',
                            borderRadius: 0,
                            fontSize: 'larger',
                        }}
                    />

                    {formErros && formErros.tel ?
                        <div
                            className="input-data"
                            style={{
                                color: 'red',
                                fontSize: 12,
                                marginTop: 6,
                            }}
                        >
                            Digite um número válido
                        </div>
                        :
                        <div
                            className="input-data"
                            style={{
                                marginTop: 6,
                                fontSize: 12,
                                color: 'lightgray',
                            }}
                        >
                            Campo obrigatório
                        </div>
                    }


                    <div className='switch'>
                        <label>É um prestador de serviços?</label>
                        <Switch
                            width={50}
                            onChange={() => setToggleServices(!toggleServices)}
                            checked={toggleServices}
                            uncheckedIcon={false}
                            checkedIcon={true}
                        />
                    </div>

                    {toggleServices && (
                        <>
                            <Input
                                type='text'
                                text='Empresa'
                                value={form.empresa}
                                onChange={e => changeForm('empresa', e.target.value)}
                                required={true}
                                erro={formErros.empresa}
                                erroText="Digite o nome da empresa"
                            />
                            <Input
                                type='text'
                                text='Função'
                                value={form.funcao}
                                onChange={e => changeForm('funcao', e.target.value)}
                                required={true}
                                erro={formErros.funcao}
                                erroText="Digite a sua função na empresa"
                            />
                        </>
                    )}


                    <div className="termo">
                        <div className='termoHeader'>
                            <div className='termoInfo' >
                                Tenho ciência que os dados pessoais <br /> acima serão tratados para finalidade <br /> específica de acesso ao {dadosConvite.name_place}
                            </div>
                            <Switch
                                width={50}
                                uncheckedIcon={false}
                                checkedIcon={true}
                                checked={termo}
                                onChange={changeSwitch}
                            />
                        </div>

                        <div
                            style={{
                                color: 'red',
                                fontSize: 12,
                                marginTop: 6,
                                width: '100%'
                            }}
                        >
                            É necessário marcar esta opção para prosseguir
                        </div>


                        <button
                            disabled={!termo}
                            className='saveButton'
                            onClick={saveData}
                        >
                            Salvar
                        </button>

                    </div>
                    <Footer />
                </div>
            )} 

            {/* {toggle === 'realizado' && (
                <div className='realizado'>
                    <img src={Checked} alt='sucesso' style={{ width: '100px', heigth: '100px' }} />
                    <span><strong>{morador.map(nome => nome.nome)}</strong><br />Cadastro Realizado com sucesso!</span>
                    <p>Após a liberaçao por parte de {titleCase(dadosConvite.name_created || "")} você receberá um novo link com detalhes do covite.</p>
                </div>
            )} */}


            {toggle === 'realizado_e_confirmado' && (
                <div className='convite'>
                    <img src={Checked} alt='checado' />
                    <div className='text-container'>
                        <div style={{ textAlign: 'center' }}>{form.nome || ""}</div>
                        <div>O convite enviado por <strong>{titleCase(dadosConvite.name_created)}</strong> está confirmado.</div>
                        <div>Você tem autorização de acesso ao <strong>{dadosConvite.name_place}</strong> no período de {dadosConvite.dat_init} até {dadosConvite.dat_end}.</div>
                        <div>Para ativar o seu convite, você precisa <strong>estar no local e na data data informada.</strong></div>
                        <div>Clique no botão abaixo ou no link recebido no seu Whatsapp.</div>
                    </div>
                    <button>Ir para o convite</button>
                </div>
            )}




        {toggle === 'erro' && (
                <Erro>
                      <div className='realizado'>
                            <span>{erroTextCPF ? erroTextCPF:'Recarregue a página'}</span>
                        </div>
                </Erro>
            )}


            {toggle === 'Acesso_Face' && (
                <div className='gps-container'>
                    <Face />
                </div>
            )}

            {toggle === 'preload' && (
                <Preload />
            )}


        </div>
    )
}

export default PreCadastro