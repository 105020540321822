import React from 'react';
import Lottie from 'react-lottie';
import animation from '../animations/erro.json';

const PreLoad = props => {





    return (
        <>
            <div style={{ 
                backgroundColor: '#fff', 
                width: '100%', 
                height: '100vh', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                flexDirection: 'column', 
                padding: 30,
                paddingBottom: 60,
            }}
            >
                <Lottie
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: animation,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    height={'150px'}
                    width={'150px'}
                    style={{
                        marginBottom: 50
                    }}
                />
                {React.Children.map(props.children, (child, i) => {
                    return child;
                })}
            </div>
        </>
    )
}

export default PreLoad