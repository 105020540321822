import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useIMask } from 'react-imask';

const InputCellPhone = ({ onChange, ...restProps }) => {
  const [auxiliarValue, setAuxiliarValue] = useState(null);

  const { ref, value } = useIMask({
    mask: typeof auxiliarValue === 'string' && auxiliarValue.length > 14 ? '(00) 00000-0000' : '(00) 0000-00000',
    lazy: true,
    definitions: {
      '#': /[1-9]/,
    },
  })

  useEffect(() => {
    setAuxiliarValue(value);
  }, [value]);

  return (
    <TextField
      {...restProps}
      inputRef={ref}
      inputProps={{
        inputMode: 'numeric',
        value,
        onChange,
      }}
    />
  );
}

export default InputCellPhone
