import axios from "axios";
import axiosRetry from "axios-retry";

const httpClient = axios.create({
  baseURL: 'https://fastpass-br.gear-tecnorise.com:4001/public/'
});

axiosRetry(httpClient, { retries: 3 });

export default httpClient;
