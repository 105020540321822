import { Container, Typography, Stack } from '@mui/material';
import Lottie from 'react-lottie';
import successAnimation from '../../../components/animations/success.json'

const SuccessFormSubmit = () => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Stack alignItems="center" gap={2}>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: successAnimation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={'150px'}
          width={'150px'}
        />
        <Stack>
          <Typography variant="h4" component="h1" gutterBottom>
            Cadastro realizado com sucesso!
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Agora o condominio irá analisar suas informações.
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default SuccessFormSubmit;
