import { createContext, useCallback, useContext, useMemo, useReducer } from "react";

const defaultValue = {
  step: 0,
}

export const StepsContext = createContext(defaultValue);

const reducerSteps = (state, action) => {
  switch (action.type) {
    case 'NEXT_STEP':
      return {
        ...state,
        step: state.step + 1
      };
    case 'PREVIOUS_STEP':
      return {
        ...state,
        step: state.step - 1
      };
    default:
      return state;
  }
};

export const StepsProvider = (({ children }) => {
  const [{ step }, dispatch] = useReducer(
    reducerSteps,
    defaultValue,
  );

  const nextStep = useCallback(() => {
    dispatch({
      type: 'NEXT_STEP',
    });
  }, [dispatch]);
  const prevStep = useCallback(() => {
    dispatch({
      type: 'PREVIOUS_STEP',
    });
  }, [dispatch]);

  const value = useMemo(() => ({
    step,
    nextStep,
    prevStep,
  }), [step, nextStep, prevStep]);

  return (
    <StepsContext.Provider value={value}>
      {children}
    </StepsContext.Provider>
  )
})

/**
 * Returns the value of the StepsContext using the useContext hook.
 *
 * @return {{
 *  step: number,
 *  nextStep: () => void
 *  prevStep: () => void
 *  }} The value of the StepsContext.
 * @example
 * const { step, nextStep, prevStep } = useSteps();
 */
export const useSteps = () => {
  return useContext(StepsContext);
}

