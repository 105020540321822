import { Box, Button, Stack, Typography } from "@mui/material";
import { useUnitSelected } from "../contexts/unit-selected-context";
import rostoRegra from '../../../assets/rostoRegra.png';
import { useCondominio } from "../contexts/condominio-context";
import { useSteps } from "../contexts/steps-context";

function TakePicture() {
  const { unitSelected } = useUnitSelected();
  const { nomeDoCondominio } = useCondominio();
  const { nextStep } = useSteps();

  return (
    <Stack spacing={4}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body1">
          Você selecionou a unidade:
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" >
          <Typography variant="h6">
            {unitSelected.name}
          </Typography>
        </Box>
      </Stack>

      <Typography variant="body2">
        Vamos precisar coletar a sua foto para identificação no acesso ao <strong>{nomeDoCondominio}</strong>.
      </Typography>

      <Box>
        <Typography variant="body1">Na hora da foto, veja as dicas:</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3, }}>
          <img
            src={rostoRegra}
            alt='dica'
            height={270}
            width={310}
            style={{ marginTop: 20 }}
          />
        </Box>
      </Box>

      <Button variant="contained" size="large" onClick={nextStep}>
        Iniciar Câmera
      </Button>
      {/* close camera */}
      {/* acmera viewer */}
      {/* button take a picture */}
    </Stack>
  )
}

export default TakePicture;
