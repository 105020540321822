import presencialFoto from '../assets/presencial.png'
import './Face.css'


const Presencial = props => {
    return(
             
             <div className='presencialHeader'>
                <img src={presencialFoto} alt="presencialFoto"  />
                <div>APROXIME-SE DO LEITOR FACIAL, A SUA FOTO JÁ ESTÁ CADASTRADA</div>
            </div>
        
    )
}

export default Presencial