import {
  Container,
  Typography,
  Button,
  Modal,
  Paper,
  Stack,
  IconButton,
} from '@mui/material';
import { MdClose } from 'react-icons/md';
import Lottie from 'react-lottie';
import { red } from '@mui/material/colors';
import errorAnimation from '../../../components/animations/erro.json'


const FailedFormSubmitModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
    >
      <Container component={Paper} elevation={3} maxWidth="sm" sx={{ marginTop: '50px', paddingY: '40px' }}>
        <Stack direction="row" justifyContent="end">
          <IconButton onClick={onClose}>
            <MdClose />
          </IconButton>
        </Stack>
        <Stack alignItems="center" gap={3}>
          <Stack alignItems="center">
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: errorAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={'150px'}
              width={'150px'}
            />
            <Typography variant="h6" color={red[800]} gutterBottom>
              Falha ao enviar os dados
            </Typography>
            <Typography variant="body1" color="textSecondary" textAlign="center">
              Houve uma falha ao enviar os dados. Por favor, tente novamente.
            </Typography>
          </Stack>
          <Button variant="text" color="info" onClick={onClose}>
            Voltar para o formulário
          </Button>
        </Stack>
      </Container>
    </Modal>
  );
};

export default FailedFormSubmitModal;
