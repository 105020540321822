import "./App.css";
import "@fontsource/roboto";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";

//  import Main from './pages/Main';
//  import CondInfo from './pages/CondInfo';
//   import NovoCadastro from './pages/NovoCadastro';
//  import CadastroRealizado from './pages/CadastroRealizado'
// import WebConvite from './pages/WebConvite'
//  import QrCode from './pages/QrCodeMain';
//  import CadFacial from './pages/CadFacial';
//  import Cadastro from './pages/Cadastro';
// import Expirado from './pages/Expirado'
// import Face from './pages/Face';
import PreCadastro from "./pages/PreCadastro";
import Convite from "./pages/Convite";
// import PreLoad from './pages/PreLoad';
import Evento from "./pages/Evento";
import { useEffect } from "react";
import Qrcode from "./pages/Qrcode";
import WebcamComponent from "./pages/WebcamApp";
import PreRegister from "./pages/pre-register";
import { Providers } from "./pages/pre-register/contexts/providers";
function App() {
  useEffect(() => {
    // if (navigator.userAgent.indexOf("CriOS") == -1) {
    //   window.location.href="googlechromes"+window.location.href.substring(5);
    // }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/pre-register" element={<Providers><PreRegister /></Providers>} />
        <Route path="/p" element={isMobile ? <PreCadastro /> : <></>} />
        <Route path="/c" element={isMobile ? <Convite /> : <></>} />
        <Route path="/e" element={isMobile ? <Evento /> : <></>} />
        <Route path="/q" element={isMobile ? <Qrcode /> : <></>} />
        <Route path="/w" element={<WebcamComponent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
