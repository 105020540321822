import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CondominioProvider } from "./condominio-context"
import { PreRegisterFormProvider } from "./pre-register-form-context"
import { StepsProvider } from "./steps-context"
import ThemeContextProvider from "./theme-context"
import { UnitSelectedProvider } from "./unit-selected-context"

export const Providers = ({ children }) => {
  return (
    <ThemeContextProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CondominioProvider>
          <StepsProvider>
            <UnitSelectedProvider>
              <PreRegisterFormProvider>
                {children}
              </PreRegisterFormProvider>
            </UnitSelectedProvider>
          </StepsProvider>
        </CondominioProvider>
      </LocalizationProvider>
    </ThemeContextProvider>
  )
}
