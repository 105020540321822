import Footer from '../../components/Footer'
import './style.css'
import Dica from '../../assets/rostoRegra.png'


function Tela({ info = {}, onClick }) {

    //console.log({ info })

    return (
        <div className="container" >


            <div className='info' style={{ maxWidth: 340, }}>
                <header
                    className='headerInfo'
                >
                    <p>VOCÊ TEM UM CONVITE</p>
                </header>
                <p>
                    <span className='textDestaque'>{info.name_created}</span>, enviou um convite para que você acesse o <span className='textDestaque'>{info.name_place}</span> no período de <span className='textDestaque'>{info.dat_init}</span> até <span className='textDestaque'>{info.dat_end}</span>.
                    <br/>
                    <br/>
                    <span className='textDestaque'>Endereço:</span> {info.address}.

                </p>

                {(info.id_visitor && !info.flg_validation) ?
                    <p>
                        <div style={{ width: 280 }}>Para utilizar seu convite é preciso acessar este link novamente <span className='textDestaque'>no local</span> e na <span className='textDestaque'>data informada</span>, para que as instruções de acesso sejam exibidas.</div>
                    </p>
                    :
                    <>
                        <p>
                            Para prosseguir, é necessário fazer o seu cadastro. Vamos precisar coletar a sua foto para identificação, pelo que você
                            tem ciência de que a sua biometria será utilizada para finalidade específica de acesso.
                        </p>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <button className='buttonInfo' onClick={() => onClick('cadastro')}>Avançar</button>
                        </div>
                    </>
                }



            </div>

            {(!info.id_visitor || info.flg_validation) &&
                <div className="dicas">
                    <p>Na hora da foto, veja as dicas:</p>
                    <img src={Dica} alt='dica' style={{ marginTop: 20 }} />
                </div>
            }

            <Footer />
        </div>
    )
}

export default Tela