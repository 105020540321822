import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: '#317bff',
    },
  },
});

function ThemeContextProvider({children}) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default ThemeContextProvider
