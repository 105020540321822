import React, { useRef } from 'react';
import { ChevronRight,Lock,Unlock } from 'react-feather';
import { useState } from 'react';
import Lottie from 'react-lottie';
import animationData from '../animations/setas.json'
import './style.css'
export default function InputRange({button,onOpenButton}) {
  const inputElement = useRef();
  const containerElement = useRef();
  const [range, setRange] = useState(0);
  const [active, setActive] = useState(true);
  const [touch, setTouch] = useState(false);
  const [viewTrigger, setViewTrigger] = useState(false);


  function onClick(e) {
    setTouch(true);
    let containerDiv = containerElement.current.offsetWidth;
    //if (active) {
      if (
        e.touches[0].clientX > 20 &&
        e.touches[0].clientX + 50 < containerDiv
      ) {
        inputElement.current.style.left = `${e.touches[0].clientX}px`;
        // setRange(range + e.touches[0].clientX);
      }
    //}
  }

  function back(e) {

    setTouch(false);
    let position =
      inputElement.current.style.left &&
      parseInt(inputElement.current.style.left.replace('px', ''));
    position = position + 50;
    let containerDiv = containerElement.current.offsetWidth;

    if (position >= containerDiv - 20) {
      acionar(e)
     // setActive(false);
    } 

    inputElement.current.style.left = '';

  }


  function acionar(e){
  onOpenButton()
    setViewTrigger(true)
    setTimeout(()=>{
      setViewTrigger(false)
    },2000)
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="input-range">
            <span className="input-range-name">{button.name}</span>

      {!viewTrigger ? (
        <div ref={containerElement} className={'input-range-container'}>
          <div
            ref={inputElement}
            className="input-range-element"
            onTouchMoveCapture={e => onClick(e)}
            onTouchEndCapture={e => back(e)}
            draggable={true}
          >
            <ChevronRight color="#FFFFFF" size={50} />
          </div>
          <div
            className={
              touch ? 'input-range-lottie' : 'input-range-lottie-no-active'
            }
          >
            {touch ? (
              <Lottie
                options={defaultOptions}
                height={40}
                width={70}
                isStopped={false}
                isPaused={false}
                style={{ margin: 0 }}
              />
            ) : (
              <p className="input-range-middle">Arraste para acionar</p>
            )}
          </div>
          <div className="input-range-lock">
          <Lock color="#FFFFFF" size={20} />

            {/* <LockOpen fontSize="large" style={{ color: '#fff' }} /> */}
          </div>
        </div>
      ) : (
        <div ref={containerElement} className={'input-range-container-trigger'}>
          <div />
          <div>
            <p className="input-range-middle" style={{ fontSize: 16 }}>
              ABERTO
            </p>
          </div>
          <div style={{ marginRight: 20 }}>
            {/* <Done style={{ color: '#fff', fontSize: 40, fontWeight: 'bold' }} /> */}
            <Unlock color="#FFFFFF" size={20} />

          </div>
        </div>
      )}
    </div>
  );
}
