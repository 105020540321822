import { Link,useLocation} from 'react-router-dom'
import React, { useEffect,useState,useRef } from 'react';


import QrReader from 'react-qr-reader'
import './QrCode.css'
import Lottie from 'react-lottie';
import animationData from '../components/animations/qrload.json';
import animationDataQR from '../components/animations/qr.json';
import Preload from './PreLoad'
import Success from '../components/alerta/success'
import Erro from '../components/alerta/erro'
import DetalheConvite from './DetalheConvite'
import axios from 'axios';



const PreLoad = props => {

    const route = useLocation()
    
    const [dadosConvite,setDadosConvite] = useState({})
    const [listButton,setListButton] = useState([])

    const isOnQR = useRef(true)

    

    const [toggle, setToggle] = useState('qrcode');

    useEffect(() => {
        // console.log(route.state)
        // setDadosConvite(route.state.dadosConvite)
        if(route.search == ''){
            window.location.href = `${window.location.href}?1`

        }else{
            const info = window.localStorage.getItem('@info')
            if(info){
                const dados = JSON.parse(info)
                setDadosConvite(dados.dadosConvite)
                setListButton(dados.listButton)
            }
            window.localStorage.removeItem('@info');

        }
// console.log(window.location.href)
    }, [])


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationDataQR,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    

    const onQrCode = (qr) => {
        if (isOnQR.current) {
            isOnQR.current = false
            const button = listButton.find(b => b.qr == qr.split("#")[0])
            if (button) {

                acioinarAcesso({ "qr_code": qr, "id_trigger": button.id_trigger }, toggle)
            }
            setTimeout(() => {
                isOnQR.current = true
            }, 3000)
        }


    }


    const acioinarAcesso = async (d) => {
        try {
                setToggle('preload');

            const res = await axios.post('https://fastpass-br.gear-tecnorise.com:4001/public/invitation/access/trigger', {
                "id_invitation": dadosConvite.id_invitation,
                "id_unit": dadosConvite.id_unity,
                "type_acess": 'qrcode',
                ...d
            });

        

                if (res.data.status) {
                    setToggle('alert_success');
                    setTimeout(() => {
                        setToggle('qrcode');
                    }, 2000)
                } else {
                    setToggle('alert_erro');
                    setTimeout(() => {
                        setToggle('qrcode');
                    }, 2000)
                }
            

            // return res.data.resultado


        } catch (e) {
                setToggle('alert_erro');
                setTimeout(() => {
                    setToggle('qrcode');
                }, 2000)
            
            // console.log({ e })
        }

    };

    
    return(
        <>

{toggle === 'qrcode' && (

             <div className="main">

<div style={{ position: 'absolute', top: '10px', width: '300px', height: '300px', overflow: 'hidden' }} className="qrimage">
    <QrReader
        //onResult={qrcodeResult}
        showViewFinder={false}
        style={{ width: '100%', borderRadius: '10px' }}
        constraints={{
            facingMode: 'environment'
        }}
        onResult={(result, error) => {

        }}
        onScan={(result, error) => {
            if (result) onQrCode(result)
        }}
        onError={(result, error) => {
            // console.log({ result, error })
        }}
    />
</div>
{/* <div style={{position:'relative', top:'30px', color:'white'}}>Escaneie o QR Code</div> */}
<div style={{ position: 'relative', top: '0px', width: '70%' }}>
    <Lottie
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
        style={{ margin: 0 }} />
</div>

<div className='qrinfo'>
    <div style={{ margin: '15px 10px 64px' }}>
        <p style={{ margin: '20px', marginTop: '40px', }}>INSTRUÇÃO DE USO</p>
        <div className="instrucao">
            <div className="loading" >
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    height={'150px'}
                    width={'150px'}

                />
            </div>
            <div className="info" >No condomínio, localize o QR Code e aponte a câmera para ele</div>
        </div>
    </div>

    <DetalheConvite dadosConvite={dadosConvite} />

</div>
</div>

)}

{toggle === 'preload' && (
                <Preload />
            )}

            {toggle === 'alert_success' && (
                <Success />
            )}

            {toggle === 'alert_erro' && (
                <Erro>
                    {/* <div className='realizado'>
                            <span>Recarregue a página</span>
                        </div> */}
                </Erro>
            )}

        </>
    )
}
export default PreLoad