// import { Link,useLocation} from 'react-router-dom'
// import React, { useEffect } from 'react';



  

import ReactLoading from 'react-loading';

const PreLoad = props => {

//     const route = useLocation()
    


//     useEffect(() => {
//         // console.log(route.state)
//         console.log(route)
//         console.log(route.key)
//         if(route.search == ''){
//             window.location.href = `${window.location.href}?1`
//             // window.location.reload();

//         }
// console.log(window.location.href)
//     }, [])


    
    return(
        <>
                <div style={{ width:'100%', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}
                ><ReactLoading type='spokes' color="black" height={100} width={50} />Aguarde...</div>


        </>
    )
}
export default PreLoad