import './DetalheConvite.css'
import React, { useEffect, useState, useRef, useCallback } from 'react'


const Detalhe = ({ dadosConvite }) => {
    return (
        <div style={{ borderRadius: '20px 20px 0px 0px', width: '100vw', padding: '40px', background:'#F5F5F5' }}>
            <div>
                <div style={{ fontFamily: 'Roboto', color: '#424242' }}>DETALHES DO CONVITE</div>
                <div style={{ display: 'flex', fontSize: '10px', lineHeight: '10px', alignItems: 'center', justifyContent: 'space-around', margin: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 105 }}>
                        <div style={{ backgroundColor: '#28A745', color: 'white', borderRadius: '50%', padding: '6px' }} className="material-symbols-outlined">transfer_within_a_station</div>
                        <div>
                            <div style={{ color: "#424242" }}>Pedestre</div>
                            <div style={{ color: "#424242" }}>AUTORIZADO</div>
                        </div>
                    </div>
                    {dadosConvite.isVehicle ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 105 }}>
                            <div style={{ backgroundColor: '#28A745', color: '#fff', borderRadius: '50%', padding: '6px' }} className="material-symbols-outlined">directions_car</div>
                            <div>
                                <div style={{ color: '#424242' }}>Veículo</div>
                                <div style={{ color: '#424242' }}>AUTORIZADO</div>
                            </div>
                        </div>
                        :
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 120 }}>
                            <div style={{ color: '#dc3545', fontSize: '35px' }} className="material-symbols-outlined">block</div>
                            <div style={{ color: '#dc3545' }}>
                                <div>Veículo</div>
                                <div>NÃO AUTORIZADO</div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <div style={{ marginBottom: '15px', color: '#424242' }}>
                        <div style={{ fontSize: '12px' }}>Observação:</div>
                        <div style={{ fontSize: '12px' }}>{dadosConvite.obs}</div>
                    </div>
                    <div style={{ marginBottom: '15px', color: '#424242' }}>
                        <div style={{ fontSize: '12px' }}>Local:</div>
                        <div style={{ fontSize: '12px' }}>{dadosConvite.name_place}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', width: '70%', color: "#424242" }}>
                        <div>
                            <div style={{ marginBottom: '15px' }}>
                                <div>A partir de:</div>
                                <div>{dadosConvite.dat_init}</div>
                            </div>
                            <div>
                                <div>Anfitrião:</div>
                                <div>{dadosConvite.name_created}, {dadosConvite.unity}</div>
                            </div>
                        </div>
                        <div>
                            <div style={{ marginBottom: '15px' }}>
                                <div>Válido até</div>
                                <div>{dadosConvite.dat_end}</div>
                            </div>
                            <div>
                                <div>Evento:</div>
                                <div>Não há registro</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <p style={{ marginBottom: '10px', fontFamily:'Roboto' }}>DETALHES DO CONVITE</p>
            <div style={{ display: 'flex', fontSize: '10px', lineHeight: '15px', alignItems: 'center', justifyContent: 'space-between', margin: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', width:105 }}>
                    <div style={{ backgroundColor: '#28A745', color: 'white', borderRadius: '50%', padding: '6px' }} className="material-symbols-outlined">transfer_within_a_station</div>
                    <span style={{ fontWeight: '' }}>Pedestre <br /> AUTORIZADO</span>
                </div>
                {dadosConvite.isVehicle ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', width:105 }}>
                        <div style={{ backgroundColor: '#28A745', color: '#fff', borderRadius: '50%', padding: '6px' }} className="material-symbols-outlined">directions_car</div>
                        <span style={{ fontWeight: '' }}>Veículo<br />AUTORIZADO</span>
                    </div>
                    :
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ color: '#dc3545',   fontSize:'35px' }} className="material-symbols-outlined">block</div>
                        <span style={{ color:'#dc3545', fontFamily: 'Roboto', fontWeight: '500', fontSize:'10px' }}>Veículo<br />NÃO AUTORIZADO</span>
                    </div>
                }
            </div>
            


            <p style={{ lineHeight: '20px', fontSize: '12px' }}>Observação: <br /> {dadosConvite.dsc_note}</p>
            <div style={{ margin: "15px", fontSize: '12px' }}>Local:<br />{dadosConvite.name_place}</div>
            <div style={{ display: 'flex', justifyContent: 'space-around', fontSize: '12px', width: '100%' }}>
                <div style={{ width: '50%' }}>
                    A partir de:<br />{dadosConvite.dat_init}
                </div>
                <div>Valído até:<br />{dadosConvite.dat_end}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'left', fontSize: '12px', }}>
                <div>
                    Anfitrião: <br /> {dadosConvite.name_created}, {dadosConvite.unity}
                </div>
                <div>
                    Evento: <br /> Não há registro
                </div>
            </div> */}
        </div>
    )

}

export default Detalhe